import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function() {
    if($(".schedule-slide").length > 0){
      scheduleSlide();

      $(window).on("resize", function(){
        if($(window).width() > 992){
          scheduleSlide();
        }
      }) // end resize

      $(".control-btn").on("click", function(e){
        var action = $(this).data("action");

        if(action == "prev"){
          $(".schedule-slide").slick('slickPrev');
        }else if(action == "next"){
          $(".schedule-slide").slick('slickNext');
        }
  
        e.preventDefault();
      });
    }else{
      sessionStorage.setItem('slickNum',-1)

    } // end if

    if($(".info-slide").length > 0){
      infoSlide();

      $(window).on("resize", function(){
          infoSlide();
      }) // end resize
    } // end if

    if($(".exhibition-slide").length > 0){
      var exhibitionSlide = $(".exhibition-slide").slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          swip: true,
          variableWidth: false,
          autoplay: false,
          autoplaySpeed: 5000,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            }
          ]
      });

      $(".slide-btn").on("click", function(e){
        if($(this).hasClass('left')){
          exhibitionSlide.slick('slickPrev');
        }else{
          exhibitionSlide.slick('slickNext');
        }

        e.preventDefault();
      })

      // $(".exhibition-slide").on('beforeChange', function(){
      //   $(".exhibition-carousel").find(".active").removeClass("active");
      // });

      // $(".exhibition-slide").on('afterChange', function(event, slick, currentSlide, nextSlide){
      //   $(".exhibition-carousel").find(".active").removeClass("active");
      //   $(".exhibition-carousel").find("button:eq(" + currentSlide + ")").addClass("active");
      // });
      

    } // end if

    if($("#relItem").length > 0){
      relSlide();
      
      $(window).on("resize", function(){
          relSlide();
      }) // end resize
    } // end if

    if($(".grids").length > 0){
      gridSlide();

      $(window).on("resize", function(){
          gridSlide();
      }) // end resize
    } // end if

});

function scheduleSlide(){
  var startItem = $(".schedule-slide").find("a.active").index();
  let slickNum = sessionStorage.getItem('slickNum') != -1 ? sessionStorage.getItem('slickNum')  : startItem;
  $(".schedule-slide").slick({
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    swip: false,
    variableWidth: false,
    autoplay: false,
    initialSlide: slickNum,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1661,
        settings: {
          swip: true
        }
      },
      {
        breakpoint: 992,
        settings: "unslick"
      }
    ]
  });

  if($(window).width() > 992){
    $(".schedule-slide").slick('refresh');
  }
} // end function

function infoSlide(){
  if($(".thumb-nav-slide").length > 0){
    var settings = {
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      swip: false,
      variableWidth: true,
      centerMode: true,
      autoplay: true,
      asNavFor: '.thumb-nav-slide',
      autoplaySpeed: 5000
    }
  }else{
    var settings = {
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      swip: false,
      variableWidth: true,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 5000
    }
  }

  $(".info-slide").removeClass("d-none");
  var exhibitionSlide = $(".info-slide");

  if($(window).width() <= 991){
    if (exhibitionSlide.hasClass('slick-initialized')) {
      exhibitionSlide.slick("unslick");
    }
  }else if(!exhibitionSlide.hasClass('slick-initialized')) {
    exhibitionSlide.slick(settings);
  }
  
  exhibitionSlide.on('afterChange', function(event, slick, currentSlide, nextSlide){
    var title = $(".slick-current").next(".slick-slide").find(".info-slide-item").data("title");
    $(".next-step").find(".txt").html(title);
  });

  $(".next-step").on("click", function(e){
    exhibitionSlide.slick("slickNext");
    e.preventDefault();
  })

  $(".thumb-nav-slide").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    swip: false,
    variableWidth: false,
    autoplay: false,
    autoplaySpeed: 5000,
    asNavFor: '.info-slide',
    dots: false,
    centerMode: true,
    focusOnSelect: true
  });
      
} // end function

function gridSlide(){
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swip: false,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 5000
  };

  var aboutSlide = $(".grids");

  if($(window).width() >= 992){
    if (aboutSlide.hasClass('slick-initialized')) {
        aboutSlide.slick("unslick");
    }
  }else if(!aboutSlide.hasClass('slick-initialized')) {
    aboutSlide.slick(settings);
  }
} // end function

function relSlide(){
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swip: false,
    variableWidth: false,
    autoplay: false,
    autoplaySpeed: 5000,
  }

  var relSlide = $("#relItem");

  if($(window).width() >= 768){
    if (relSlide.hasClass('slick-initialized')) {
      relSlide.slick("unslick");
    }
  }else if(!relSlide.hasClass('slick-initialized')) {
    relSlide.slick(settings);
  }
} // end function
